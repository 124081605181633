import {fetcher, handleResponse} from "@/_helpers/api";
import {
    getPageStatusMethod,
    getPageStatusUrl,
    getUsersDetailsUrl,
    getUsersDetailsMethod,
    getUpdateSettingsUrl,
    getUpdateSettingsMethod,
    sendTestEmailUrl,
    sendTestEmailMethod, getPageAboutMethod, getPageAboutUrl
} from "@/_helpers/admin_helper";


export const adminService = {
    getPageStatus,
    getPageAbout,
    getUsersDetails,
    updateSetting,
    sendTestEmail
};

function getPageStatus() {
    let requestOptions = {
        method: getPageStatusMethod(),
        headers: {"Content-Type": "application/json"},
    };

    return fetcher(getPageStatusUrl(), requestOptions).then(handleResponse);
}

function getPageAbout() {
    let requestOptions = {
        method: getPageAboutMethod(),
        headers: {"Content-Type": "application/json"},
    };

    return fetcher(getPageAboutUrl(), requestOptions).then(handleResponse);
}

function getUsersDetails() {
    let requestOptions = {
        method: getUsersDetailsMethod(),
        headers: {"Content-Type": "application/json"},
    };

    return fetcher(getUsersDetailsUrl(), requestOptions).then(handleResponse);
}

function updateSetting(data) {
    let requestOptions = {
        method: getUpdateSettingsMethod(),
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data),
    };

    return fetcher(getUpdateSettingsUrl(), requestOptions).then(handleResponse);
}

function sendTestEmail() {

    let data = {
        "test": true
    }

    let requestOptions = {
        method: sendTestEmailMethod(),
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data),
    };

    return fetcher(sendTestEmailUrl(), requestOptions).then(handleResponse);
}