<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="page-header">
          <slot name="header"></slot>
        </h1>
      </div>
    </div>
    <hr class="m-t-0"/>
  </div>
</template>

<script>
export default {
  name: "RMPageHeader",
}
</script>