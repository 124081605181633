import {config} from "@/config/config";

export function getPageStatusUrl() {
    return `${config.apiUrl}${config.admin.status.url}`;
}

export function getPageStatusMethod() {
    return config.admin.status.method;
}

export function getPageAboutUrl() {
    return `${config.apiUrl}${config.admin.about.url}`;
}

export function getPageAboutMethod() {
    return config.admin.about.method;
}

export function getUsersDetailsUrl() {
    return `${config.apiUrl}${config.admin.users.url}`;
}

export function getUsersDetailsMethod() {
    return config.admin.users.method;
}

export function getUpdateSettingsUrl() {
    return `${config.apiUrl}${config.admin.settingsUpdate.url}`;
}

export function getUpdateSettingsMethod() {
    return config.admin.settingsUpdate.method;
}

export function sendTestEmailUrl() {
    return `${config.apiUrl}${config.admin.sendTestEmail.url}`;
}

export function sendTestEmailMethod() {
    return config.admin.sendTestEmail.method;
}